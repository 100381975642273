import React from 'react'
import { Header, Layout, Scramble } from '../../components'

export default () => (
  <Layout>
    <div>
      <Header><Scramble>Projects</Scramble></Header>
      <hr />
      <p>Nothing here yet.</p>
    </div>
  </Layout>
)
